// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 60px;
    font-family: 'Roboto', sans-serif;
    max-width: 800px;
    padding: 0 20px;
  }
  
  .title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #4285f4;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/BeatPackLicense/BeatPackLicense.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;EAChB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: auto;\n    margin-top: 60px;\n    font-family: 'Roboto', sans-serif;\n    max-width: 800px;\n    padding: 0 20px;\n  }\n  \n  .title {\n    font-size: 36px;\n    font-weight: 700;\n    margin-bottom: 20px;\n    color: #4285f4;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
